import type { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import { DashboardUtils } from './DashboardUtils';

/**
 * Determines which Dashboard ID should be shown based on either the dashboard ID in the navigation hash, the last
 * accessed dashboard for the currently selected project (based on the user options) or the first dashboard ID that is
 * accessible as fallback.
 */
export class DashboardResolver {
	/**
	 * The available dashboards filtered to the dashboards that are owned by the current user or that are explicitly
	 * shared with the user. Does not contain dashboards that are only visible because of the user's admin permissions.
	 */
	private dashboardsOwnedByOrSharedWithMe: UserResolvedDashboardDescriptor[] = [];

	/** The name of the last accessed dashboard of the current user. */
	private lastAccessedDashboard: string | null = null;
	private dashboards: UserResolvedDashboardDescriptor[] = [];

	public constructor(
		private readonly hash: NavigationHash,
		private readonly option: LastDashboardOpenedByUserOption
	) {}

	/**
	 * Ensures that there is a dashboard name set (ID parameter in navigation hash) when the view expects one and
	 * redirects if necessary. This ensures that the empty view name is set exactly when no dashboard exists for the
	 * selected project and that the user is on the show, kiosk, edit or sharing page otherwise when a valid ID is set.
	 */
	public determineDashboardIdToShow(dashboards: UserResolvedDashboardDescriptor[]): string | null {
		this.dashboards = dashboards;
		this.dashboardsOwnedByOrSharedWithMe = dashboards.filter(
			dashboard => dashboard.isMine || !dashboard.isPrivate,
			this
		);
		this.lastAccessedDashboard = this.getLastAccessedDashboardFromUserOptions();

		return this.getDashboardFromHashOrStored(dashboards);
	}

	/**
	 * @returns Nothing is stored. Fetches the last accessed dashboard for the current user, which is stored as user
	 *   option. Note that the returned dashboard may no longer exist, which is _not_ checked by this method.
	 */
	public getLastAccessedDashboardFromUserOptions(): string | null {
		const lastAccessedDashboardIdsByProject = DashboardUtils.loadLastAccessedDashboardIdsByProject(this.option);
		const projectId = this.hash.getProject();
		let keyToLookup = projectId;
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			keyToLookup = DashboardUtils.LAST_ACCESSED_DASHBOARD_FOR_ALL_PROJECTS_KEY;
		}
		return lastAccessedDashboardIdsByProject.get(keyToLookup) ?? null;
	}

	/**
	 * Gets the dashboard ID from the navigation hash, or the last-accessed dashboard of the current user if it still
	 * exists.
	 */
	public getDashboardFromHashOrStored(dashboards: UserResolvedDashboardDescriptor[]): string | null {
		const dashboardIdOrName = this.hash.getId();
		const dashboardDescriptor = DashboardResolver.findDashboardByIdOrQualifiedName(dashboards, dashboardIdOrName);
		if (dashboardDescriptor != null) {
			return dashboardDescriptor.id!;
		}
		if (dashboardIdOrName) {
			return dashboardIdOrName;
		}
		return this.getLastAccessedOrFirstOtherDashboardSafe(dashboards);
	}

	/**
	 * Returns the name of the last accessed dashboard if it still exists on the server. Otherwise, returns the first
	 * other dashboard from {@link #dashboards}, or null.
	 *
	 * @returns The name of the dashboard to show or null if there are no dashboards.
	 */
	protected getLastAccessedOrFirstOtherDashboardSafe(dashboards: UserResolvedDashboardDescriptor[]): string | null {
		const dashboardDescriptor = DashboardResolver.findDashboardByIdOrQualifiedName(
			dashboards,
			this.lastAccessedDashboard
		);
		if (dashboardDescriptor != null) {
			return dashboardDescriptor.id!;
		}

		// Choose the first dashboard instead (or return null)
		if (this.dashboardsOwnedByOrSharedWithMe.length > 0) {
			return this.dashboardsOwnedByOrSharedWithMe[0]!.id!;
		}
		if (this.dashboards.length > 0) {
			return this.dashboards[0]!.id!;
		}
		return null;
	}

	/**
	 * @returns The dashboard with the given ID or qualified name or null if no such dashboard was found. We allow
	 *   qualified names her for backwards compatibility to keep existing bookmarks working.
	 */
	private static findDashboardByIdOrQualifiedName(
		dashboards: UserResolvedDashboardDescriptor[],
		dashboardIdOrQualifiedName: string | null
	): UserResolvedDashboardDescriptor | null {
		if (StringUtils.isEmptyOrWhitespace(dashboardIdOrQualifiedName)) {
			return null;
		}
		return (
			dashboards.find(
				dashboard =>
					dashboard.id === dashboardIdOrQualifiedName ||
					DashboardResolver.buildQualifiedDashboardName(dashboard.owner, dashboard.name) ===
						dashboardIdOrQualifiedName
			) ?? null
		);
	}

	/**
	 * Builds the qualified name of the given dashboard template.
	 *
	 * @param owner The owner of the dashboard.
	 * @param name The name of the dashboard.
	 * @returns The qualified name of the dashboard.
	 */
	private static buildQualifiedDashboardName(owner: string | undefined, name: string): string {
		return owner + '/' + name;
	}
}
