import { QUERY } from 'api/Query';
import { Message } from 'ts/components/Message';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody } from 'ts/components/Table';

/** The active committers dialog shows committers from the last 90 days and maps them to Teamscale users. */
export function ActiveCommittersDialog() {
	const activeCommitters = QUERY.getListOfActiveCommitters({}).useSuspendingQuery();
	if (activeCommitters.length === 0) {
		return <Message>No active committers found.</Message>;
	}
	return (
		<div className="w-[500px]">
			Lists everyone that has committed code that is analyzed by Teamscale within the last 90 days and the mapping
			between Teamscale users and commit authors in the version control system.
			<Table selectable striped compact unstackable className="borderless framed body-code-table">
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Teamscale User</TableHeaderCell>
						<TableHeaderCell>Aliases</TableHeaderCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					{activeCommitters.map((committer, index) => (
						<tr key={committer.userName + index}>
							<td>
								{committer.userName ? (
									committer.userName + ' (' + committer.fullName + ')'
								) : (
									<i>None</i>
								)}
							</td>
							<td>{committer.commaSeparatedAliases ? committer.commaSeparatedAliases : <i>None</i>}</td>
						</tr>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
