// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/MetricsUtilsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.MetricsUtilsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$UIUtilsTemplate from './UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$rating.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $rating = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $rating$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.tooltip, opt_data.widths, opt_data.colors, opt_data.sortKey, opt_data.isEmpty);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} tooltip
 * @param {!Array<number>} widths
 * @param {!Array<string>} colors
 * @param {string} sortKey
 * @param {?=} isEmpty
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $rating$ = function($$areYouAnInternalCaller, $ijData, tooltip, widths, colors, sortKey, isEmpty) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.rating']) {
    return soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.rating']({tooltip: tooltip, widths: widths, colors: colors, sortKey: sortKey, isEmpty: isEmpty}, $ijData);
  }
  soy.assertParamType(typeof tooltip === 'string', 'tooltip', tooltip, '@param', 'string');
  soy.assertParamType(Array.isArray(widths), 'widths', widths, '@param', '!Array<number>');
  soy.assertParamType(Array.isArray(colors), 'colors', colors, '@param', '!Array<string>');
  soy.assertParamType(typeof sortKey === 'string', 'sortKey', sortKey, '@param', 'string');
  let $output = '<div data-sort-value="' + soy.$$escapeHtmlAttribute(sortKey) + '"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + tooltip)) + ' class="metric-rating"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.MetricsUtilsTemplate.rating ts/commons/MetricsUtilsTemplate.soy:25"' : '') + '>';
  const i2282_RangeEnd = widths.length;
  const i2282ListLen = Math.max(0, Math.ceil((i2282_RangeEnd - 0) / 1));
  for (let i2282Index = 0; i2282Index < i2282ListLen; i2282Index++) {
    const i2282Data = 0 + i2282Index * 1;
    $output += '<div class="rating-child" style="background: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(colors[/** @type {?} */ (i2282Data)])) + '; width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(widths[/** @type {?} */ (i2282Data)])) + 'px"></div>';
  }
  $output += '</div>' + (isEmpty ? '<i class="icon info circle empty-assessment-hint"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Nothing to assess in this file (e.g. no methods)')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.MetricsUtilsTemplate.rating ts/commons/MetricsUtilsTemplate.soy:34"' : '') + '></i>' : '');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $rating as rating };
export { $rating$ as rating$ };
/**
 * @typedef {{
 *  tooltip: string,
 *  widths: !Array<number>,
 *  colors: !Array<string>,
 *  sortKey: string,
 *  isEmpty?: (?|undefined),
 * }}
 */
$rating.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $rating.soyTemplateName = 'ts.commons.MetricsUtilsTemplate.rating';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $missingData = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.missingData']) {
    return soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.missingData'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span class="no-data"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'No data available')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.MetricsUtilsTemplate.missingData ts/commons/MetricsUtilsTemplate.soy:43"' : '') + '>N/A</span>');
};
export { $missingData as missingData };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $missingData.soyTemplateName = 'ts.commons.MetricsUtilsTemplate.missingData';
}


/**
 * @param {!$stringValuedMetric.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $stringValuedMetric = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $stringValuedMetric$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.value, opt_data.tooltip, opt_data.sortValue, opt_data.color);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} value
 * @param {string} tooltip
 * @param {null|number|string|undefined=} sortValue
 * @param {null|string|undefined=} color
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $stringValuedMetric$ = function($$areYouAnInternalCaller, $ijData, value, tooltip, sortValue, color) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.stringValuedMetric']) {
    return soy.$$stubsMap['ts.commons.MetricsUtilsTemplate.stringValuedMetric']({value: value, tooltip: tooltip, sortValue: sortValue, color: color}, $ijData);
  }
  soy.assertParamType(typeof value === 'string', 'value', value, '@param', 'string');
  soy.assertParamType(typeof tooltip === 'string', 'tooltip', tooltip, '@param', 'string');
  soy.assertParamType(sortValue == null || typeof sortValue === 'number' || typeof sortValue === 'string', 'sortValue', sortValue, '@param', 'null|number|string|undefined');
  soy.assertParamType(color == null || typeof color === 'string', 'color', color, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="metric-rating"' + (sortValue != null ? ' data-sort-value="' + soy.$$escapeHtmlAttribute(sortValue) + '"' : '') + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + tooltip)) + (color != null ? ' style="font-weight: 600; color: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(color)) + '"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.MetricsUtilsTemplate.stringValuedMetric ts/commons/MetricsUtilsTemplate.soy:59"' : '') + '>' + (soy.$$equals(color, '#44bf70') ? '<i aria-hidden="true" class="green check circle icon"></i>' : '') + (soy.$$equals(color, '#fde725') ? '<i aria-hidden="true" class="yellow minus circle icon"></i>' : '') + (soy.$$equals(color, '#dd513a') ? '<i aria-hidden="true" class="red times circle icon"></i>' : '') + soy.$$escapeHtml(value) + '</div>');
};
export { $stringValuedMetric as stringValuedMetric };
export { $stringValuedMetric$ as stringValuedMetric$ };
/**
 * @typedef {{
 *  value: string,
 *  tooltip: string,
 *  sortValue?: (null|number|string|undefined),
 *  color?: (null|string|undefined),
 * }}
 */
$stringValuedMetric.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $stringValuedMetric.soyTemplateName = 'ts.commons.MetricsUtilsTemplate.stringValuedMetric';
}
